
.checkbox-container {
    width: 100%;
    height: 100%;
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    --green: green;
    background: white;
  }
  
  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox]:checked + label.checkbox .checkbox__inner .green__ball {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    transition-delay: 150ms;
  }
  input[type=checkbox]:checked ~ .checkbox__text {
    opacity: 1;
  }
  input[type=checkbox]:checked ~ .checkbox__text .checkbox__text--options span.off {
    transform: translateY(150%);
    opacity: 0;
  }
  input[type=checkbox]:checked ~ .checkbox__text .checkbox__text--options span.on {
    transform: translateY(0%);
    opacity: 1;
  }
  
  .checkbox {
    --size: 30px;
    display: block;
    width: var(--size);
    height: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--bg);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.12), 2px 2px 6px rgba(0, 0, 0, 0.05), 2px 2px 10px rgba(0, 0, 0, 0.025), inset -2px -2px 3px rgba(0, 0, 0, 0.05), inset -2px -2px 8px rgba(0, 0, 0, 0.02), inset 1px 3px 3px rgba(255, 255, 255, 0.45), inset 3px 8px 25px rgba(255, 255, 255, 0.35), inset 3px 2px 3px rgba(255, 255, 255, 0.35), inset 3px 2px 5px rgba(255, 255, 255, 0.2), inset 2px 3px 8px rgba(255, 255, 255, 0.085), inset 3px 2px 18px rgba(255, 255, 255, 0.05), inset 2px 3px 25px rgba(255, 255, 255, 0.025), inset 8px 8px 18px rgba(255, 255, 255, 0.1), inset 8px 8px 25px rgba(255, 255, 255, 0.05);
    cursor: pointer;
  }
  
  .checkbox .checkbox__inner {
    position: relative;
    width: calc(var(--size) / 1.75);
    height: calc(var(--size) / 1.75);
    border-radius: 50%;
    background: var(--bg);
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.12), inset 2px 2px 5px rgba(0, 0, 0, 0.08), inset 3px 3px 12px rgba(0, 0, 0, 0.05), inset 4px 5px 16px rgba(0, 0, 0, 0.035), inset 0px -1px 2px rgba(255, 255, 255, 0.45), inset -1px -1px 3px rgba(255, 255, 255, 0.45), inset -1px -1px 2px rgba(255, 255, 255, 0.2), inset -1px -1px 2px rgba(255, 255, 255, 0.12), 2px 2px 2px rgba(255, 255, 255, 0.12), 2px 2px 3px rgba(255, 255, 255, 0.1), 2px 2px 5px rgba(255, 255, 255, 0.08), 6px 6px 15px rgba(0, 0, 0, 0.014), 8px 8px 18px rgba(0, 0, 0, 0.08), 12px 12px 28px rgba(0, 0, 0, 0.04);
  }
  
  .checkbox .checkbox__inner .green__ball {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--green);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.12), inset -4px -5px 12px rgba(0, 0, 0, 0.12), inset -5px -6px 12px rgba(0, 0, 0, 0.08), inset 0px -6px 18px rgba(0, 0, 0, 0.06), 2px 1px 8px rgba(152, 255, 112, 0.32), 3px 2px 12px rgba(152, 255, 112, 0.15), 4px 4px 18px rgba(152, 255, 112, 0.08);
    transition: transform 250ms var(--transition-easing), opacity 300ms var(--transition-easing);
    transition-delay: 120ms;
  }
  .checkbox .checkbox__inner .green__ball::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 35%;
    height: 15%;
    filter: blur(4px);
  }
  
  .checkbox__text {
    margin-left: 2rem;
    font-size: 1.2rem;
    font-family: sans-serif;
    color: transparent;
    color: rgba(0, 0, 0, 1);
    display: flex;
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 250ms var(--transition-easing);
    transition-delay: 150ms;
  }
  .checkbox__text--options {
    position: relative;
    margin: 0 0.5rem;
  }
  .checkbox__text--options span {
    position: absolute;
    left: 0%;
    top: 0%;
    transition: transform 250ms var(--transition-easing), opacity 150ms var(--transition-easing);
    transition-delay: 150ms;
  }
  .checkbox__text--options span.off {
    transform: translateY(0%);
    opacity: 1;
  }
  .checkbox__text--options span.on {
    transform: translateY(-150%);
    opacity: 0;
  }
  