
.page-skeleton-container {
	width: 100%;
	max-width: 100% !important;
	height: 100%;
	display:grid;
    grid-template-columns: 200px 1fr 200px;
	grid-template-rows: 50px 1fr ;
	grid-template-areas:
		'navbar navbar navbar'
		'left middle right';
}

.collapse-left {
	grid-template-columns: 0px 1fr 200px;
}

.collapse-right {
	grid-template-columns: 200px 1fr 0px;
}

.collapse-all {
	grid-template-columns: 0px 1fr 0px;

}

.nav {
	grid-area: navbar;

}

.leftnav {
	grid-area: left;
	overflow-y: scroll;
	height: calc(100vh - 3.1rem);
	width: 100%;
}

.main {

	grid-area: middle;
	overflow-y: scroll;
	height: calc(100vh - 3.1rem);
	

}

.rightnav {
	grid-area: right;
	overflow-y: scroll;
	height: calc(100vh - 3.1rem);
	width: 100%;
}